import {
   FILTER_CLEAR,
   FILTER_REMOVE_VENDEDOR,
   FILTER_ADD_VENDEDOR,
   FILTER_PERIODO,
   FILTER_ESTADO_PAGO,
   RESULTADOS_REPORTE_VENTAS,
   FILTER_REMOVE_ESTATUS_PEDIDO,
   FILTER_ADD_ESTATUS_PEDIDO,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_DEPARTAMENTO,
   FILTER_EMPRESA,
   FILTER_TIPO_CLIENTE,
   FILTER_REMOVE_TIPO_ATENCION,
   FILTER_ADD_TIPO_ATENCION,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_REMOVE_METODO_ENTREGA,
   FILTER_ORDEN_CLIENTE,
   FILTER_PEDIDO_ID,
   FILTER_COTIZACION_ID,
   FILTER_NOTA_VENTA,
   FILTER_BUSQUEDA_PRODUCTO,
   FILTER_BUSQUEDA
} from "../redux-types";

export const departamentoFilter = (data) => ({ type: FILTER_DEPARTAMENTO, payload: data })

export const periodoFilter = (data) => ({ type: FILTER_PERIODO, payload: data })

export const fechaInicioFilter = (data) => ({ type: FILTER_FECHA_INICIO, payload: data })

export const fechaFinalFilter = (data) => ({ type: FILTER_FECHA_FINAL, payload: data })

export const estadoPagoFilter = (data) => ({ type: FILTER_ESTADO_PAGO, payload: data })

export const empresaFilter = (data) => ({ type: FILTER_EMPRESA, payload: data })

export const tipoClienteFilter = (data) => ({ type: FILTER_TIPO_CLIENTE, payload: data })

export const ordenClienteFilter = (data) => ({ type: FILTER_ORDEN_CLIENTE, payload: data })

export const cotizacionFilter = (data) => ({ type: FILTER_COTIZACION_ID, payload: data })

export const pedidoFilter = (data) => ({ type: FILTER_PEDIDO_ID, payload: data })

export const notaVentaFilter = (data) => ({ type: FILTER_NOTA_VENTA, payload: data })

export const busquedaProductoFilter = (data) => ({ type: FILTER_BUSQUEDA_PRODUCTO, payload: data })

export const busquedaClienteFilter = (data) => ({ type: FILTER_BUSQUEDA, payload: data })


export const vendedorFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_VENDEDOR, payload: data }
      : { type: FILTER_ADD_VENDEDOR, payload: data }

export const estatusPedidoFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_ESTATUS_PEDIDO, payload: data }
      : { type: FILTER_ADD_ESTATUS_PEDIDO, payload: data }

export const tipoAtencionFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_TIPO_ATENCION, payload: data }
      : { type: FILTER_ADD_TIPO_ATENCION, payload: data }

export const metodosEntregasFilter = (data, isRemove = false) =>
   isRemove
      ? { type: FILTER_REMOVE_METODO_ENTREGA, payload: data }
      : { type: FILTER_ADD_METODO_ENTREGA, payload: data }

export const resultadosReporteVentas = (data) => ({ type: RESULTADOS_REPORTE_VENTAS, payload: data })

export const clearFilterReporteVentas = () => ({ type: FILTER_CLEAR });
