import {
	FILTER_AUTOGESTION_INPUT,
	FILTER_CLEAR,
	RESULTADOS_AUTOGESTION,
} from "../redux-types";

const initialState = {
	filtros: {
		comuna_id: '',
		// pedido_id: '',
		nota_venta: '',
		fecha_desde: '',
		fecha_hasta: '',
		sucursal_id: ''
	},
	resultados: null
}


export const autogestionReducer = (state = initialState, action) => {

	switch (action.type) {
		case FILTER_AUTOGESTION_INPUT:
			const { key, value } = action.payload

			return {
				...state, filtros: {
					...state.filtros,
					[key]: value
				}
			};

		case RESULTADOS_AUTOGESTION:
			return {
				...state, resultados: action.payload
			};

		case FILTER_CLEAR:
			return initialState;

		default:
			return state;
	}
};