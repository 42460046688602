import {
   FILTER_NOMBRE_CLIENTE,
   FILTER_CLEAR,
   FILTER_PAIS,
   FILTER_FECHA_INICIO,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_AGENDA,
   FILTER_ADD_USUARIO,
   FILTER_REMOVE_USUARIO,
   FILTER_REMOVE_PUNTO_ENTREGA,
   FILTER_ADD_PUNTO_ENTREGA,
   FILTER_REMOVE_METODO_ENTREGA,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_ADD_METODO_PAGO,
   FILTER_REMOVE_METODO_PAGO,
   FILTER_REMOVE_DIVISA,
   FILTER_ADD_DIVISA,
   FILTER_REMOVE_TIPO_FACTURACION,
   FILTER_ADD_TIPO_FACTURACION,
   FILTER_REMOVE_ESTATUS_PEDIDO,
   FILTER_ADD_ESTATUS_PEDIDO,
   RESULTADOS_PEDIDOS,
   FILTER_PEDIDO_ID,
   FILTER_ADD_ESTATUS_PAGO,
   FILTER_REMOVE_ESTATUS_PAGO,
   FILTER_ADD_FRONTEND,
   FILTER_REMOVE_FRONTEND,
   FILTROS_PERSISTENTES_PEDIDOS
} from "../redux-types";


const initialState = {
   filtros: {
      // params
      busqueda_usuario: '',
      pais_id: '',
      desde: '',
      hasta: '',
      fecha: '',

      // body
      usuario_id: [],
      frontend_id: [],
      puntoentrega_id: [],
      metodo_entrega_id: [],
      metodo_pago_id: [],
      divisa_id: [],
      tipo_facturacion_id: [],
      estatus_pedido_id: [],
      estado_pago: [],
      id: ''
   },
   resultados: null
}


export const pedidosReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_NOMBRE_CLIENTE:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda_usuario: action.payload
            }
         }

      case FILTER_PEDIDO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               id: action.payload
            }
         }

      case FILTER_PAIS:
         return {
            ...state, filtros: {
               ...state.filtros,
               pais_id: action.payload
            }
         }

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               desde: action.payload
            }
         }

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               hasta: action.payload
            }
         }

      case FILTER_FECHA_AGENDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha: action.payload
            }
         }

      case FILTER_ADD_FRONTEND:
         isListed = state.filtros.frontend_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               frontend_id: isListed
                  ? state.filtros.frontend_id
                  : [...state.filtros.frontend_id, action.payload]
            }
         };

      case FILTER_REMOVE_FRONTEND:
         return {
            ...state, filtros: {
               ...state.filtros,
               frontend_id: state.filtros.frontend_id.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_ESTATUS_PAGO:
         isListed = state.filtros.estado_pago.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               estado_pago: isListed
                  ? state.filtros.estado_pago
                  : [...state.filtros.estado_pago, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS_PAGO:
         return {
            ...state, filtros: {
               ...state.filtros,
               estado_pago: state.filtros.estado_pago.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_USUARIO:
         isListed = state.filtros.usuario_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               usuario_id: isListed
                  ? state.filtros.usuario_id
                  : [...state.filtros.usuario_id, action.payload]
            }
         };

      case FILTER_REMOVE_USUARIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               usuario_id: state.filtros.usuario_id.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_PUNTO_ENTREGA:
         isListed = state.filtros.puntoentrega_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               puntoentrega_id: isListed
                  ? state.filtros.puntoentrega_id
                  : [...state.filtros.puntoentrega_id, action.payload]
            }
         };

      case FILTER_REMOVE_PUNTO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               puntoentrega_id: state.filtros.puntoentrega_id.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_METODO_ENTREGA:
         isListed = state.filtros.metodo_entrega_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_entrega_id: isListed
                  ? state.filtros.metodo_entrega_id
                  : [...state.filtros.metodo_entrega_id, action.payload]
            }
         };

      case FILTER_REMOVE_METODO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_entrega_id: state.filtros.metodo_entrega_id.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_METODO_PAGO:
         isListed = state.filtros.metodo_pago_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_pago_id: isListed
                  ? state.filtros.metodo_pago_id
                  : [...state.filtros.metodo_pago_id, action.payload]
            }
         };

      case FILTER_REMOVE_METODO_PAGO:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodo_pago_id: state.filtros.metodo_pago_id.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_DIVISA:
         isListed = state.filtros.divisa_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               divisa_id: isListed
                  ? state.filtros.divisa_id
                  : [...state.filtros.divisa_id, action.payload]
            }
         };

      case FILTER_REMOVE_DIVISA:
         return {
            ...state, filtros: {
               ...state.filtros,
               divisa_id: state.filtros.divisa_id.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_TIPO_FACTURACION:
         isListed = state.filtros.tipo_facturacion_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               tipo_facturacion_id: isListed
                  ? state.filtros.tipo_facturacion_id
                  : [...state.filtros.tipo_facturacion_id, action.payload]
            }
         };

      case FILTER_REMOVE_TIPO_FACTURACION:
         return {
            ...state, filtros: {
               ...state.filtros,
               tipo_facturacion_id: state.filtros.tipo_facturacion_id.filter(el => el !== action.payload)
            }
         }

      case FILTER_ADD_ESTATUS_PEDIDO:
         isListed = state.filtros.estatus_pedido_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_pedido_id: isListed
                  ? state.filtros.estatus_pedido_id
                  : [...state.filtros.estatus_pedido_id, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS_PEDIDO:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_pedido_id: state.filtros.estatus_pedido_id.filter(el => el !== action.payload)
            }
         }

      case RESULTADOS_PEDIDOS:
         return { ...state, resultados: action.payload }

      case FILTROS_PERSISTENTES_PEDIDOS:
         return { ...state, filtros: action.payload }

      case FILTER_CLEAR:
         return { ...state, filtros: initialState.filtros };

      default:
         return state;
   }
};