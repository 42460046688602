import axios from "axios"
import { successResponse } from "helpers/successResponse"
import { getAll } from "endpoints/getAll"
import { queryParamsFormatter } from "helpers/queryParamsFormatter"


const URL = getAll.cuentas_caja_erp

export const getAllCuentasCajasErp = async (limit = 99999, params = {}, paginationUrl = undefined) => {
  try {
    const response = await axios(`${paginationUrl ?? URL}${paginationUrl ? '&' : '?'}limit=${limit}${queryParamsFormatter(params)}`, {
      headers: {
        'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
      },
    })

    if (!successResponse(response)) throw response

    return response.data
  } catch (err) {
    console.error(err)
    return err.response
  }
}