// AUTH
export const AUTH_LOGIN = "[AUTH] login"
export const AUTH_PERSISTENT = "[AUTH] persistent"

// ACCORDION FILTERS

export const FILTER_AUTOGESTION_INPUT = "[FILTERS] autogestion input"

export const FILTER_REPLANIFICACION_INPUT = "[FILTERS] replanificacion input"

export const FILTER_AUTOSERVICIO_SUCURSALES_INPUT = "[FILTERS] autoservicio sucursales input"

export const FILTER_AUTOSERVICIO_USUARIOS_SUCURSALES_INPUT = "[FILTERS] autoservicio usuarios sucursales input"

export const FILTER_LIMITE = "[FILTERS] limite"
export const FILTER_ESTATUS = "[FILTERS] estatus";
export const FILTER_ADD_ESTATUS = "[FILTERS] add estatus";
export const FILTER_REMOVE_ESTATUS = "[FILTERS] remove estatus";

export const FILTER_ADD_OFERTA = "[FILTERS] add oferta";
export const FILTER_REMOVE_OFERTA = "[FILTERS] remove oferta";

export const FILTER_ADD_ADICIONALES = "[FILTERS] add adicionales";
export const FILTER_REMOVE_ADICIONALES = "[FILTERS] remove adicionales";

export const FILTER_ADD_METODOS_PAGO = "[FILTERS] add metodos pago";
export const FILTER_REMOVE_METODOS_PAGO = "[FILTERS] remove metodos pago";

export const FILTER_ADD_ETIQUETA = "[FILTER] add etiqueta";
export const FILTER_REMOVE_ETIQUETA = "[FILTER] remove etiqueta";

export const FILTER_ADD_CLAVE = "[FILTER] add clave";
export const FILTER_REMOVE_CLAVE = "[FILTER] remove clave";

export const FILTER_ADD_CATEGORIA = "[FILTER] add categoria";
export const FILTER_REMOVE_CATEGORIA = "[FILTER] remove categoria";

export const FILTER_ADD_CLAVE_ATRIBUTO = '[FILTER] add clave-atributo'
export const FILTER_REMOVE_CLAVE_ATRIBUTO = '[FILTER] remove clave-atributo'

export const FILTER_ADD_ORDEN_PROVEEDOR = '[FILTER] add orden proveedor'
export const FILTER_REMOVE_ORDEN_PROVEEDOR = '[FILTER] remove orden proveedor'

export const FILTER_METODO_PAGO = "[FILTER] metodo pago";
export const FILTER_ADD_METODO_PAGO = "[FILTER] add metodo pago";
export const FILTER_REMOVE_METODO_PAGO = "[FILTER] remove metodo pago";

export const FILTER_METODO_ENTREGA = '[FILTER] metodo entrega'
export const FILTER_ADD_METODO_ENTREGA = '[FILTER] add metodo entrega'
export const FILTER_REMOVE_METODO_ENTREGA = '[FILTER] remove metodo entrega'

export const FILTER_ESTATUS_PEDIDO = '[FILTER] estatus pedido'
export const FILTER_ADD_ESTATUS_PEDIDO = '[FILTER] add estatus pedido'
export const FILTER_REMOVE_ESTATUS_PEDIDO = '[FILTER] remove estatus pedido'

export const FILTER_ADD_ESTATUS_PAGO = "[FILTER] add estatus pago";
export const FILTER_REMOVE_ESTATUS_PAGO = "[FILTER] remove estatus pago";

export const FILTER_ADD_ATRIBUTOS = "[FILTER] add atributos";
export const FILTER_REMOVE_ATRIBUTOS = "[FILTER] remove atributos";

export const FILTER_PUNTO_ENTREGA = "[FILTER] punto entrega";
export const FILTER_ADD_PUNTO_ENTREGA = "[FILTER] add punto entrega";
export const FILTER_REMOVE_PUNTO_ENTREGA = "[FILTER] remove punto entrega";

export const FILTER_COMUNA_ENTREGA = "[FILTER] comuna entrega";
export const FILTER_ADD_COMUNA_ENTREGA = "[FILTER] add comuna entrega";
export const FILTER_REMOVE_COMUNA_ENTREGA = "[FILTER] remove comuna entrega";

export const FILTER_ADD_ELEMENTO = "[FILTER] add elemento";
export const FILTER_REMOVE_ELEMENTO = "[FILTER] remove elemento";

export const FILTER_ADD_TABLA = "[FILTER] add tabla";
export const FILTER_REMOVE_TABLA = "[FILTER] remove tabla";

export const FILTER_ADD_EJECUTIVO = "[FILTER] add ejecutivo";
export const FILTER_REMOVE_EJECUTIVO = "[FILTER] remove ejecutivo";

export const FILTER_ADD_PAIS_CLIENTE = "[FILTER] add pais cliente";
export const FILTER_REMOVE_PAIS_CLIENTE = "[FILTER] remove pais cliente";

export const FILTER_ADD_PAIS = "[FILTER] add pais";
export const FILTER_REMOVE_PAIS = "[FILTER] remove pais";

export const FILTER_ADD_UNIDAD = '[FILTER] add unidad'
export const FILTER_REMOVE_UNIDAD = '[FILTER] remove unidad'

export const FILTER_ADD_REGION = '[FILTER] add region'
export const FILTER_REMOVE_REGION = '[FILTER] remove region'

export const FILTER_ADD_COMUNA = "[FILTER] add comuna";
export const FILTER_REMOVE_COMUNA = "[FILTER] remove comuna";

export const FILTER_ADD_ATRIBUTO_RELACION = "[FILTER] add atributo relacion";
export const FILTER_REMOVE_ATRIBUTO_RELACION = "[FILTER] remove atributo relacion";

export const FILTER_ADD_ATRIBUTO_VALOR_PADRE = "[FILTER] add atributo valor padre";
export const FILTER_REMOVE_ATRIBUTO_VALOR_PADRE = "[FILTER] remove atributo valor padre";

export const FILTER_ADD_ATRIBUTO_VALOR_HIJO = '[FILTER] add atributo valor hijo'
export const FILTER_REMOVE_ATRIBUTO_VALOR_HIJO = '[FILTER] remove atributo valor hijo'

export const FILTER_TIPO_DESTINO = '[FILTER] tipo destino'
export const FILTER_ADD_TIPO_DESTINO = '[FILTER] add tipo destino'
export const FILTER_REMOVE_TIPO_DESTINO = '[FILTER] remove tipo destino'

export const FILTER_CODIGO_VENDEDOR = '[FILTER] tipo codigo vendedor'
export const FILTER_ADD_CODIGO_VENDEDOR = '[FILTER] add tipo codigo vendedor'
export const FILTER_REMOVE_CODIGO_VENDEDOR = '[FILTER] remove tipo codigo vendedor'

export const FILTER_ADD_BODEGA = '[FILTER] add bodega'
export const FILTER_REMOVE_BODEGA = '[FILTER] remove bodega'

export const FILTER_ADD_DESTINO = '[FILTER] add destino'
export const FILTER_REMOVE_DESTINO = '[FILTER] remove destino'

export const FILTER_ADD_VENDEDOR = '[FILTER] add vendedor'
export const FILTER_REMOVE_VENDEDOR = '[FILTER] remove vendedor'

export const FILTER_ADD_PROVEEDOR = '[FILTER] add proveedor'
export const FILTER_REMOVE_PROVEEDOR = '[FILTER] remove proveedor'

export const FILTER_ADD_SOLICITANTE = '[FILTER] add solicitante'
export const FILTER_REMOVE_SOLICITANTE = '[FILTER] remove solicitante'

export const FILTER_ADD_FACTURACION = '[FILTER] add facturacion'
export const FILTER_REMOVE_FACTURACION = '[FILTER] remove facturacion'

export const FILTER_ADD_TIPO_ATENCION = '[FILTER] add tipo atencion'
export const FILTER_REMOVE_TIPO_ATENCION = '[FILTER] remove tipo atencion'

export const FILTER_ADD_FORMA_PAGO = '[FILTER] add tipo forma pago'
export const FILTER_REMOVE_FORMA_PAGO = '[FILTER] remove forma pago'

export const FILTER_ADD_ANCHO = '[FILTER] add ancho'
export const FILTER_REMOVE_ANCHO = '[FILTER] remove ancho'

export const FILTER_ADD_ALTO = '[FILTER] add alto'
export const FILTER_REMOVE_ALTO = '[FILTER] remove alto'

export const FILTER_ADD_EVENTO = '[FILTER] add evento'
export const FILTER_REMOVE_EVENTO = '[FILTER] remove evento'

export const FILTER_ADD_ARO = '[FILTER] add aro'
export const FILTER_REMOVE_ARO = '[FILTER] remove aro'

export const FILTER_ADD_PRODUCTO = '[FILTER] add producto'
export const FILTER_REMOVE_PRODUCTO = '[FILTER] remove producto'
export const FILTER_ADD_PRODUCTO_PROVEEDOR = '[FILTER] add producto proveedor'
export const FILTER_REMOVE_PRODUCTO_PROVEEDOR = '[FILTER] remove producto proveedor'
export const FILTER_BUSQUEDA = '[FILTER] busqueda'
export const FILTER_BANCO_ID = '[FILTER] banco id'
export const FILTER_MOTIVO_ID = '[FILTER] motivo id'
export const FILTER_TIPO_ID = '[FILTER] empresa tipo id'
export const FILTER_MODELO = '[FILTER] modelo'
export const FILTER_MODELO_ID = '[FILTER] modelo id'
export const FILTER_PROVEEDOR_CATEGORIA = '[FILTER] proveedor categoria'
export const FILTER_ADD_PROVEEDOR_CATEGORIA = '[FILTER] add proveedor categoria'
export const FILTER_REMOVE_PROVEEDOR_CATEGORIA = '[FILTER] remove proveedor categoria'
export const FILTER_BUSQUEDA_PRODUCTO = '[FILTER] busqueda producto'
export const FILTER_SKUS_PRODUCTO = '[FILTER] skus producto'
export const FILTER_DXC = '[FILTER] dxc'
export const FILTER_TABLA = '[FILTER] tabla'
export const FILTER_BUSQUEDA_CRITERIO = '[FILTER] busqueda criterio'
export const FILTER_CRITERIO = '[FILTER] criterio'
export const FILTER_DESC_PRODUCTO = '[FILTER] desc producto'
export const FILTER_INTERVALO_HORA = '[FILTER] intervalo hora'
export const FILTER_NOTA_VENTA = '[FILTER] nota venta'
export const FILTER_ADD_NOTA_VENTA = '[FILTER] add nota venta'
export const FILTER_REMOVE_NOTA_VENTA = '[FILTER] remove nota venta'
export const FILTER_FECHA_AGENDA = '[FILTER] fecha agenda'
export const FILTER_FECHA_PEDIDO = '[FILTER] fecha pedido'
export const FILTER_FECHA_INICIO = '[FILTER] fecha inicio'
export const FILTER_FECHA_FINAL = '[FILTER] fecha final'
export const FILTER_PLANIFICACION_INICIO = '[FILTER] planificacion inicio'
export const FILTER_PLANIFICACION_FINAL = '[FILTER] planificacion final'
export const FILTER_PEDIDO_ID = '[FILTER] pedido id'
export const FILTER_ESTADO_ID = '[FILTER] estado id'
export const FILTER_POSICION_ID = '[FILTER] posicion id'
export const FILTER_DAÑO_ID = '[FILTER] daño id'
export const FILTER_RESULTADO_ID = '[FILTER] resultado id'
export const FILTER_RESPUESTA_ID = '[FILTER] respuesta id'
export const FILTER_FACTURA = '[FILTER] factura'
export const FILTER_CANTIDAD_RESULTADOS = '[FILTER] cantidad resultados'
export const FILTER_OC = '[FILTER] oc'
export const FILTER_ADD_OC = '[FILTER] add oc'
export const FILTER_REMOVE_OC = '[FILTER] remove oc'
export const FILTER_NV = '[FILTER] nv'
export const FILTER_NP = '[FILTER] np'
export const FILTER_SKU = '[FILTER] sku'
export const FILTER_ADD_SKU = '[FILTER] add sku'
export const FILTER_REMOVE_SKU = '[FILTER] remove sku'
export const FILTER_DIRECCION_ENTREGA = '[FILTER] direccion entrega'
export const FILTER_RUT_CLIENTE = '[FILTER] rut cliente'
export const FILTER_RUT_EMPRESA = '[FILTER] rut empresa'
export const FILTER_NOMBRE_CLIENTE = '[FILTER] nombre cliente'
export const FILTER_EMAIL_CLIENTE = '[FILTER] add email cliente'
export const FILTER_APELLIDO_CLIENTE = '[FILTER] apellido cliente'
export const FILTER_TELEFONO_CLIENTE = '[FILTER] telefono cliente'
export const FILTER_DIRECCION = '[FILTER] direccion'
export const FILTER_PRECIO_DESDE = '[FILTER] precio desde'
export const FILTER_PRECIO_HASTA = '[FILTER] precio hasta'
export const FILTER_COTIZACION_ID = '[FILTER] cotizacion id'
export const FILTER_OBSERVACIONES = '[FILTER] observaciones'
export const FILTER_GIRO = '[FILTER] giro'
export const FILTER_DIAS_VIGENCIA = '[FILTER] dias vigencia'
export const FILTER_RAZON_SOCIAL = '[FILTER] razon social'
export const FILTER_CODIGO_DESCUENTO = '[FILTER] codigo descuento'
export const FILTER_PAIS = '[FILTER] pais'
export const FILTER_DESCRIPCION = '[FILTER] descripcion'
export const FILTER_PUBLICADO = '[FILTER] publicado'
export const FILTER_FECHA_CREACION_DESDE = '[FILTER] fecha creacion desde'
export const FILTER_FECHA_CREACION_HASTA = '[FILTER] fecha creacion hasta'
export const FILTER_FECHA_RECEPCION_DESDE = '[FILTER] fecha recepcion desde'
export const FILTER_FECHA_RECEPCION_HASTA = '[FILTER] fecha recepcion hasta'
export const FILTER_FECHA_ENVIO_DESDE = '[FILTER] fecha envio desde'
export const FILTER_FECHA_ENVIO_HASTA = '[FILTER] fecha envio hasta'

export const FILTER_ORDEN_CLIENTE = '[FILTER] orden cliente'
export const FILTER_TIPO_CLIENTE = '[FILTER] tipo cliente'
export const FILTER_EMPRESA = '[FILTER] empresa'
export const FILTER_ESTADO_PAGO = '[FILTER] estado pago'
export const FILTER_DEPARTAMENTO = '[FILTER] departamento'
export const FILTER_PERIODO = '[FILTER] periodo'
export const FILTER_NOMBRE = '[FILTER] nombre' // Borrar en los demás reducers y actions
export const FILTER_ADD_NOMBRE = '[FILTER] add nombre'
export const FILTER_REMOVE_NOMBRE = '[FILTER] remove nombre'

export const FILTER_ADD_FRONTEND = '[FILTER] add frontend'
export const FILTER_REMOVE_FRONTEND = '[FILTER] remove frontend'

export const FILTER_ADD_TEXTO = '[FILTER] add texto'
export const FILTER_REMOVE_TEXTO = '[FILTER] remove texto'

export const FILTER_CREACION = '[FILTER] creacion'
export const FILTER_CREACION_DESDE = '[FILTER] creacion desde'
export const FILTER_CREACION_HASTA = '[FILTER] creacion hasta'

export const FILTER_USUARIO = '[FILTER] usuario'
export const FILTER_ADD_USUARIO = '[FILTER] add usuario'
export const FILTER_REMOVE_USUARIO = '[FILTER] remove usuario'

export const FILTER_DIVISA = '[FILTER] divisa'
export const FILTER_ADD_DIVISA = '[FILTER] add divisa'
export const FILTER_REMOVE_DIVISA = '[FILTER] remove divisa'

export const FILTER_TIPO_FACTURACION = '[FILTER] tipo facturacion'
export const FILTER_ADD_TIPO_FACTURACION = '[FILTER] add tipo facturacion'
export const FILTER_REMOVE_TIPO_FACTURACION = '[FILTER] remove tipo facturacion'

export const FILTER_LOCALE = '[FILTER] locale' // Borrar en los demás reducers y actions
export const FILTER_ADD_LOCALE = '[FILTER] add locale'
export const FILTER_REMOVE_LOCALE = '[FILTER] remove locale'

export const FILTER_PATRON_IDENTIFICACION = '[FILTER] patron identificacion' // Borrar en los demás reducers y actions
export const FILTER_ADD_PATRON_IDENTIFICACION = '[FILTER] add patron identificacion'
export const FILTER_REMOVE_PATRON_IDENTIFICACION = '[FILTER] remove patron identificacion'

export const FILTER_EXTENSION_TELEFONICA = '[FILTER] extension telefonica' // Borrar en los demás reducers y actions
export const FILTER_ADD_EXTENSION_TELEFONICA = '[FILTER] add extension telefonica'
export const FILTER_REMOVE_EXTENSION_TELEFONICA = '[FILTER] remove extension telefonica'

export const FILTER_COD_MAXIMISE = '[FILTER] cod maximise' // Borrar en los demás reducers y actions
export const FILTER_ADD_COD_MAXIMISE = '[FILTER] add cod maximise'
export const FILTER_REMOVE_COD_MAXIMISE = '[FILTER] remove cod maximise'

export const FILTER_CLEAR = '[FILTER] clear'
export const FILTER_CLEAR_PRODUCTOS = '[FILTER] clear filters productos'
export const FILTER_CLEAR_PRODUCTOS_ETIQUETAS = '[FILTER] clear filters productos etiquetas'
export const FILTER_CLEAR_PRODUCTOS_IMAGENES = '[FILTER] clear filters productos imagenes'
export const FILTER_CLEAR_PRODUCTOS_ADICIONALES = '[FILTER] clear filters productos adicionales'
export const FILTER_CLEAR_PRODUCTOS_PUNTOS_ENTREGA = '[FILTER] clear filters productos puntos entrega'
export const FILTER_CLEAR_PRODUCTOS_METODOS_PAGO = '[FILTER] clear filters productos metodos pago'
export const FILTER_CLEAR_PRODUCTOS_OFERTAS = '[FILTER] clear filters productos ofertas'
export const FILTER_CLEAR_ETIQUETAS = '[FILTER] clear filters etiquetas'

export const TOASTER_CONTENT = '[TOASTER] content'
export const TOASTER_CLEAR = '[TOASTER] clear'

export const LISTED_RESULTS = '[TABLES] listed results'
export const DISPLAY_MODAL = '[MODAL] display'
export const CLEAR_MODAL_PRODUCTOS = '[MODAL-PRODUCTOS] clear modal productos'
export const RESULTADOS_MODAL_PRODUCTOS = '[MODAL-PRODUCTOS] resultados modal productos'
export const FILTROS_PERSISTENTES_MODAL_PRODUCTOS = '[MODAL-PRODUCTOS] filtros persistentes'


export const RESULTADOS_PAISES = '[PAISES] resultados'
export const RESULTADOS_REGIONES = '[REGIONES] resultados'
export const RESULTADOS_COMUNAS = '[COMUNAS] resultados'
export const RESULTADOS_TIPOS_ELEMENTOS = '[TIPOS-ELEMENTOS] resultados'
export const RESULTADOS_CATEGORIAS = '[CATEGORIAS] resultados'
export const RESULTADOS_CINTA_INFORMATIVA = '[CINTA-INFORMATIVA] resultados'
export const RESULTADOS_FAQ = '[FAQ] resultados'
export const RESULTADOS_FAQ_CATEGORIAS = '[FAQ-CATEGORIAS] resultados'
export const RESULTADOS_FAQ_SUBCATEGORIAS = '[FAQ-SUBCATEGORIAS] resultados'
export const RESULTADOS_PASOS_COMPRA = '[PASOS-COMPRA] resultados'
export const RESULTADOS_BANNERS = '[BANNERS] resultados'
export const RESULTADOS_UNIDADES = '[UNIDADES] resultados'
export const RESULTADOS_USUARIOS = '[USUARIOS] resultados'
export const RESULTADOS_MENUS = '[MENUS] resultados'
export const RESULTADOS_PROVEEDORES = '[PROVEEDORES] resultados'
export const RESULTADOS_FACTORES_DIFERENCIADORES = '[FACTORES-DIFERENCIADORES] resultados'
export const RESULTADOS_TERMINOS_CONDICIONES = '[TERMINOS-CONDICIONES] resultados'
export const RESULTADOS_COMPONENTES = '[COMPONENTES] resultados'
export const RESULTADOS_PRODUCTOS_PROVEEDORES = '[PRODUCTOS-PROVEEDORES] resultados'
export const RESULTADOS_PRODUCTOS_PROPIOS_PROVEEDOR = '[PRODUCTOS-PROPIOS-PROVEEDOR] resultados'
export const RESULTADOS_HISTORIAL = '[HISTORIAL] resultados'
export const RESULTADOS_CLAVES = '[CLAVES] resultados'
export const RESULTADOS_PARAMETROS_RECARGOS = '[PARAMETROS-RECARGOS] resultados'
export const RESULTADOS_DIVISAS = '[DIVISAS] resultados'
export const RESULTADOS_EVENTOS = '[EVENTOS] resultados'
export const RESULTADOS_CLIENTES = '[CLIENTES] resultados'
export const RESULTADOS_BODEGAS_PUNTO_ENTREGA = '[BODEGAS-PUNTO-ENTREGA] resultados'
export const RESULTADOS_CLAVES_ATRIBUTOS = '[CLAVES-ATRIBUTOS] resultados'
export const RESULTADOS_TIPOS_DESTINO = '[TIPOS-DESTINO] resultados'
export const RESULTADOS_DIAS = '[DIAS] resultados'
export const RESULTADOS_METADATOS = '[METADATOS] resultados'
export const RESULTADOS_ESTATUS_PEDIDO = '[ESTATUS-PEDIDO] resultados'
export const RESULTADOS_ROLES = '[ROLES] resultados'
export const RESULTADOS_CODIGOS_DESCUENTO = '[CODIGOS-DESCUENTO] resultados'
export const RESULTADOS_VALORES_ATRIBUTOS = '[VALORES-ATRIBUTOS] resultados'
export const RESULTADOS_CRITERIOS_COMPARACION = '[CRITERIOS-COMPARACION] resultados'
export const RESULTADOS_TIPOS_FACTURACION = '[TIPOS-FACTURACION] resultados'
export const RESULTADOS_METODOS_PAGO = '[METODOS-PAGO] resultados'
export const RESULTADOS_ETIQUETAS = '[ETIQUETAS] resultados'
export const RESULTADOS_OFERTAS = '[OFERTAS] resultados'
export const RESULTADOS_PRODUCTOS = '[PRODUCTOS] resultados'
export const FILTROS_PERSISTENTES_PRODUCTOS = '[PRODUCTOS] filtros persistentes'
export const RESULTADOS_METODOS_ENTREGA = '[METODOS-ENTREGA] resultados'
export const RESULTADOS_PRODUCTOS_ETIQUETAS = '[PRODUCTOS-ETIQUETAS] resultados'
export const RESULTADOS_PRODUCTOS_OFERTAS = '[PRODUCTOS-OFERTAS] resultados'
export const RESULTADOS_PRODUCTOS_ADICIONALES = '[PRODUCTOS-ADICIONALES] resultados'
export const RESULTADOS_PRODUCTOS_IMAGENES = '[PRODUCTOS-IMAGENES] resultados'
export const RESULTADOS_PRODUCTOS_METODOS_PAGO = '[PRODUCTOS-METODOS-PAGO] resultados'
export const RESULTADOS_PRODUCTOS_PUNTOS_ENTREGA = '[PRODUCTOS-PUNTOS-ENTREGA] resultados'
export const RESULTADOS_TIPOS_ATENCION = '[TIPOS-ATENCION] resultados'
export const RESULTADOS_FORMAS_PAGO = '[FORMAS-PAGO] resultados'
export const RESULTADOS_PUNTOS_ENTREGA = '[PUNTOS-ENTREGA] resultados'
export const RESULTADOS_COTIZACIONES = '[COTIZACIONES] resultados'
export const FILTROS_PERSISTENTES_COTIZACIONES = '[COTIZACIONES] filtros persistentes'
export const RESULTADOS_ADICIONALES = '[ADICIONALES] resultados'
export const FILTROS_PERSISTENTES_ADICIONALES = '[ADICIONALES] filtros persistentes'
export const RESULTADOS_CIUDADES = '[CIUDADES] resultados'
export const RESULTADOS_SERVICIOS = '[SERVICIOS] resultados'
export const RESULTADOS_CUENTAS_CAJA = '[CUENTAS-CAJA] resultados'
export const RESULTADOS_BODEGAS = '[BODEGAS] resultados'
export const RESULTADOS_LOGISTICA_REPORTES = '[LOGISTICA-REPORTES] resultados'
export const RESULTADOS_LOGISTICA_LINEAS_DESPACHO = '[LOGISTICA-LINEAS-DESPACHO] resultados'
export const RESULTADOS_LOGISTICA_LINEAS_AGENDAMIENTOS = '[LOGISTICA-LINEAS-AGENDAMIENTOS] resultados'
export const RESULTADOS_LOGISTICA_REPLANIFICACION = '[LOGISTICA-REPLANIFICACION] resultados'
export const RESULTADOS_LOGISTICA_REPLANIFICACION_MOTIVOS = '[LOGISTICA-REPLANIFICACION-MOTIVOS] resultados'
export const RESULTADOS_DEVOLUCIONES = '[DEVOLUCIONES] resultados'
export const RESULTADOS_DEVOLUCIONES_MOTIVOS = '[DEVOLUCIONES-MOTIVOS] resultados'
export const RESULTADOS_DEVOLUCIONES_ESTATUS = '[DEVOLUCIONES-ESTATUS] resultados'
export const RESULTADOS_DEVOLUCIONES_BANCOS = '[DEVOLUCIONES-BANCOS] resultados'
export const RESULTADOS_DEVOLUCIONES_REPORTES = '[DEVOLUCIONES-REPORTES] resultados'
export const RESULTADOS_EMPRESAS = '[EMPRESAS] resultados'
export const RESULTADOS_GARANTIAS = '[GARANTIAS] resultados'
export const FILTROS_PERSISTENTES_GARANTIAS = '[GARANTIAS] filtros persistentes'
export const RESULTADOS_GARANTIAS_RESPUESTAS = '[GARANTIAS-RESPUESTAS] resultados'
export const FILTROS_PERSISTENTES_GARANTIAS_RESPUESTAS = '[GARANTIAS-RESPUESTAS] filtros persistentes'
export const FILTROS_PERSISTENTES_PARAMETROS_RECARGOS = '[PARAMETROS-RECARGOS] filtros persistentes'
export const RESULTADOS_DISTRITOS = '[DISTRITOS] resultados'
export const RESULTADOS_AGENDAMIENTOS = '[AGENDAMIENTOS] resultados'
export const RESULTADOS_PEDIDOS = '[PEDIDOS] resultados'
export const FILTROS_PERSISTENTES_PEDIDOS = '[PEDIDOS] filtros persistentes'
export const RESULTADOS_NOTAS_VENTAS = '[NOTAS-VENTAS] resultados'
export const FILTROS_PERSISTENTES_NOTAS_VENTAS = '[NOTAS-VENTAS] filtros persistentes'
export const RESULTADOS_PLANIFICACION = '[PLANIFICACION] resultados'
export const RESULTADOS_AGENDAMIENTOS_PUNTOS_ENTREGA = '[AGENDAMIENTOS-PUNTOS-ENTREGA] resultados'
export const RESULTADOS_ORDENES_COMPRA = '[ORDENES-COMPRA] resultados'
export const RESULTADOS_REPORTE_VENTAS = '[REPORTE-VENTAS] resultados'
export const RESULTADOS_RESUMEN_COTIZACIONES = '[REPORTE-COTIZACIONES] resultados'
export const RESULTADOS_AUTOGESTION = '[AUTOGESTION] resultados'
export const RESULTADOS_AUTOSERVICIO_SUCURSALES = '[AUTOSERVICIO-SUCURSALES] resultados'
export const RESULTADOS_AUTOSERVICIO_USUARIOS_SUCURSALES = '[AUTOSERVICIO-USUARIOS-SUCURSALES] resultados'
