import axios from 'axios'
import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../redux/actions/toaster'
import { FILTER_ADD_ESTATUS_PEDIDO, FILTER_ADD_METODO_ENTREGA, FILTER_ADD_TIPO_ATENCION, FILTER_ADD_VENDEDOR, FILTER_BUSQUEDA, FILTER_BUSQUEDA_PRODUCTO, FILTER_COTIZACION_ID, FILTER_DEPARTAMENTO, FILTER_EMPRESA, FILTER_ESTADO_PAGO, FILTER_FECHA_FINAL, FILTER_FECHA_INICIO, FILTER_NOTA_VENTA, FILTER_ORDEN_CLIENTE, FILTER_PEDIDO_ID, FILTER_PERIODO, FILTER_TIPO_CLIENTE } from '../../redux/redux-types'
import { resultadosProductos } from '../../redux/actions/productos'
import { busquedaClienteFilter, busquedaProductoFilter, clearFilterReporteVentas, cotizacionFilter, departamentoFilter, empresaFilter, estadoPagoFilter, estatusPedidoFilter, fechaFinalFilter, fechaInicioFilter, notaVentaFilter, ordenClienteFilter, pedidoFilter, periodoFilter, resultadosReporteVentas, vendedorFilter } from '../../redux/actions/reporteVentas'


// Components
import FormInput from '../FormInput'


// Layouts
import ListadoHeader from '../../layout/ListadoHeader'


// Endpoints
import { getAll } from '../../endpoints/getAll'


// Helpers
import { filtrosAplicadosTotal } from '../../helpers/filtrosAplicadosTotal'
import SelectFilter from '../SelectFilter'
import { lastPosition } from '../../helpers/lastPosition'
import FiltroAplicado from './FiltroAplicado'
import { idExtractor } from '../../helpers/idExtractor'
import DownloadFile from '../Download/DownloadFile'
import { getAllRoles } from 'services/usuarios/roles/getAll.service'
import { tipoAtencionFilter } from 'redux/actions/generarCotizacion'
import { getAllTiposAtencion } from 'services/tipoAtencion/getAll.service'
import { getAllMetodosEntrega } from 'services/metodosEntrega/getAll.service'
import { metodoEntregaFilter } from 'redux/actions/puntosEntrega'


const CONSTANT = {
	queryUrl: getAll.reporte_ventas,
	queryExportar: getAll.reporte_ventas + `/exportar`,
	queryExportarOrdenesCompra: getAll.reporte_ordenes_compras + `/exportar`,
	queryExportarComisiones: getAll.reporte_comisiones + `/exportar`,
	queryExportarVentasCalzadas: getAll.reporte_ventas_calzadas + `/exportar`,
	queryExportarAgendamientos: getAll.reporte_agendamientos + `/exportar`,
	queryExportarReposicionStock: getAll.reporte_reposicion_stock + `/exportar`,
	redirectUrl: '/reporte-ventas',
	title: 'Búsqueda de reporte de ventas',
	reduxClearFilters: clearFilterReporteVentas,
	reduxSetProductos: resultadosProductos,
	reduxSetResults: resultadosReporteVentas,
	fecthErpVendedores: getAll.erp_vendedores + '/obtener',
	nullObject: { id: '*NULL*', nombre: '- Error -' },
	unselectedObject: { id: '*NULL*', nombre: 'Sin selección' },
	fetchEjecutivos: getAll.usuarios + '/listar?ejecutivo=1',
	fetchEstatusPedido: getAll.estatus_pedido,

	empresasProd: [
		{ id: '*NULL*', nombre: 'Sin selección' },
		{ id: 1, nombre: 'Empresa 1' },
		{ id: 2, nombre: 'Empresa 2' }
	],
	empresasTest: [
		{ id: '*NULL*', nombre: 'Sin selección' },
		{ id: 99, nombre: 'Empresa 99' },
	],

	selectFilter: {
		vendedores: [],
		tiposAtencion: [],
		metodosEntrega: [],
		tiposCliente: [],
		departamentos: [
			{ id: '*NULL*', nombre: 'Sin selección' },
			{ id: 'ventas', nombre: 'Ventas' },
			{ id: 'reposicion_stock', nombre: 'Reposición de stock' },
			{ id: 'ventas_calzadas', nombre: 'Ventas calzadas' },
			{ id: 'comisiones', nombre: 'Comisiones' },
			{ id: 'agendamientos', nombre: 'Agendamiento' },
		],
		estadoPago: [
			{ id: '*NULL*', nombre: 'Sin selección' },
			{ id: 1, nombre: 'Pagado' },
			{ id: 0, nombre: 'No pagado' }
		],
		empresas: [],
		estatusPedido: []
	}
}

const selectDepartamentoExportar = (departamento) => {
	const key = departamento.split('%-%')[0]

	switch (key) {
		case 'ordenes_compra':
			return CONSTANT.queryExportarOrdenesCompra

		case 'agendamientos':
			return CONSTANT.queryExportarAgendamientos

		case 'ventas_calzadas':
			return CONSTANT.queryExportarVentasCalzadas

		case 'comisiones':
			return CONSTANT.queryExportarComisiones

		case 'reposicion_stock':
			return CONSTANT.queryExportarReposicionStock

		default:
			return CONSTANT.queryExportar
	}
}


const dataFormatter = (filtrosObj) => {
	let data = {}

	Object.entries(filtrosObj).forEach(([key, value]) => {
		if (value.includes('*NULL*')) return

		// ARRAYS
		if (['vendedores', 'estatus_pedido', 'tipo_atencion', 'metodos_entrega_id'].includes(key) && value.length) {
			data = {
				...data,
				...data.filtros,
				[key]: value.map(str => idExtractor(str))
			}
		}

		// STRING
		if (['periodo', 'fecha_inicial', 'fecha_final', 'busqueda_producto', 'busqueda_cliente', 'orden_cliente'].includes(key) && value.length) {
			data = {
				...data,
				...data.filtros,
				[key]: value
			}
		}

		// INTEGER
		if (['cotizacion_id', 'pedido_id', 'nota_venta'].includes(key) && value.length) {
			data = {
				...data,
				...data.filtros,
				[key]: Number(value)
			}
		}

		// ID (STRING)
		if (['departamento'].includes(key) && value.length) {
			data = {
				...data,
				...data.filtros,
				[key]: idExtractor(value, true)
			}
		}

		// ID (INTEGER)
		if (['estado_pago', 'empresa', 'role_id'].includes(key) && value.length) {
			data = {
				...data,
				...data.filtros,
				[key]: idExtractor(value)
			}
		}
	})

	return data
}


const fetchSelectFiltersData = async () => {
	const isProduction = process.env.REACT_APP_ENV === 'prod'

	const vendedores = await axios(CONSTANT.fetchEjecutivos,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
			method: 'POST'
		})
		.then(({ data }) => [CONSTANT.unselectedObject, ...data])
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const estatusPedido = await axios(CONSTANT.fetchEstatusPedido,
		{
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
		})
		.then(({ data }) => [CONSTANT.unselectedObject, ...data])
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const tiposCliente = await getAllRoles({ user_type: 'web' })
		.then(({ data }) => {
			return [CONSTANT.unselectedObject, ...data?.data]
		})
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const tiposAtencion = await getAllTiposAtencion()
		.then(({ data }) => {
			return [CONSTANT.unselectedObject, ...data?.data]
		})
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const metodosEntrega = await getAllMetodosEntrega()
		.then(({ data }) => {
			return [CONSTANT.unselectedObject, ...data?.data]
		})
		.catch(err => {
			console.error(err)
			return [CONSTANT.nullObject]
		})

	const empresas = isProduction ? CONSTANT.empresasProd : CONSTANT.empresasTest

	return { vendedores, estatusPedido, empresas, tiposCliente, tiposAtencion, metodosEntrega }
}


const ReporteVentasFiltros = ({ isAsignacionMasiva = false }) => {
	const { filtros } = useSelector(state => state.reporteVentas)
	const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilter)
	const dispatch = useDispatch()
	const {
		role_id,
		estado_pago,
		orden_cliente,
		cotizacion_id,
		pedido_id,
		nota_venta,
		busqueda_producto,
		busqueda_cliente,
		vendedores,
		estatus_pedido,
		fecha_inicial,
		fecha_final,
		departamento,
		tipo_atencion,
		metodos_entrega_id,
		empresa } = filtros
	const [iframeData, setIframeData] = useState(undefined)

	const [isConsulting, setIsConsulting] = useState(false)


	// EFECTO QUE SETEA LA DATA DE LOS SELECTFILTERS
	useEffect(() => {
		const defaultEmpresa = process.env.REACT_APP_ENV === 'prod' ? '2-Empresa 2' : '99-Empresa 99'
		fetchSelectFiltersData()
			.then(({ vendedores, estatusPedido, empresas, tiposCliente, tiposAtencion, metodosEntrega }) => {
				setSelectFilter((x) => ({ ...x, vendedores, estatusPedido, empresas, tiposCliente, tiposAtencion, metodosEntrega }))
			})

		dispatch(empresaFilter(defaultEmpresa))
	}, [dispatch])


	// EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
	useEffect(() => {
		axios(CONSTANT.queryUrl, {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
			method: 'POST'
		})
			.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
			.catch(err => {
				console.error(err)
				dispatch(CONSTANT.reduxSetResults([]))
			})

		return () => dispatch(CONSTANT.reduxClearFilters())
	}, [dispatch])


	// EFECTO QUE LIMPIA LOS FILTROS UNA VEZ DESMONTADO EL COMPONENTE
	useEffect(() => {
		return () => {
			dispatch(CONSTANT.reduxClearFilters())
		}
	}, [dispatch])


	// FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
	const handleFetch = async (reset = false, target = null) => {
		const url = target === 'exportar' ? CONSTANT.queryExportar : CONSTANT.queryUrl

		let config = {
			headers: {
				'Authorization': localStorage.getItem('x-token') ?? sessionStorage.getItem('x-token') ?? null
			},
			method: 'POST'
		}

		if (reset) {
			dispatch(CONSTANT.reduxClearFilters())

			return axios(url, config)
				.then(({ data }) => dispatch(CONSTANT.reduxSetResults(data?.adicionales)))
				.catch(err => {
					console.error(err)
					dispatch(CONSTANT.reduxSetResults([]))
				})
		}

		config = { ...config, data: dataFormatter(filtros) }

		setIsConsulting(true)

		await axios(url, config)
			.then(({ data }) => {
				if (target === 'exportar') return setIframeData(data)

				dispatch(CONSTANT.reduxSetResults(data))

				dispatch(fireToaster({ title: 'Búsqueda realizada', icon: 'success', text: 'Filtros aplicados con éxito' }))
			})
			.catch(err => {
				console.error(err)
				const { errores } = err.response.data
				let detalles = []
				Object.values(errores).forEach(errorArray => errorArray.forEach(error => detalles.push(error)))

				const toasterContent = {
					title: `
            Operación fallida
            (${err.response.status} - ${err.response.statusText})
          `,
					html: `
            <b>Detalle: </b>
            ${detalles.map(error => `<br /><i>-${error}</i>`)}
          `,
					icon: 'error'
				}
				dispatch(fireToaster(toasterContent))
				dispatch(CONSTANT.reduxSetResults([]))
			})
			.finally(() => {
				setIsConsulting(false)
			})
	}
	console.log(selectFilter);


	// MANEJADORES DE FILTRO
	const handleAddFilter = (e, filter) => {
		const { value } = e.target

		switch (filter) {
			case FILTER_DEPARTAMENTO:
				dispatch(departamentoFilter(value))
				break;

			case FILTER_FECHA_INICIO:
				dispatch(fechaInicioFilter(value))
				break;

			case FILTER_FECHA_FINAL:
				dispatch(fechaFinalFilter(value))
				break;

			case FILTER_PERIODO:
				dispatch(periodoFilter(value))
				break;

			case FILTER_ESTADO_PAGO:
				dispatch(estadoPagoFilter(value))
				break;

			case FILTER_EMPRESA:
				dispatch(empresaFilter(value))
				break;

			case FILTER_ADD_VENDEDOR:
				dispatch(vendedorFilter(value))
				break;

			case FILTER_ADD_ESTATUS_PEDIDO:
				dispatch(estatusPedidoFilter(value))
				break;

			case FILTER_ADD_TIPO_ATENCION:
				dispatch(tipoAtencionFilter(value))
				break;

			case FILTER_ADD_METODO_ENTREGA:
				dispatch(metodoEntregaFilter(value))
				break;

			case FILTER_TIPO_CLIENTE:
				dispatch(estatusPedidoFilter(value))
				break;

			case FILTER_BUSQUEDA_PRODUCTO:
				dispatch(busquedaProductoFilter(value))
				break;

			case FILTER_BUSQUEDA:
				dispatch(busquedaClienteFilter(value))
				break;

			case FILTER_ORDEN_CLIENTE:
				dispatch(ordenClienteFilter(value))
				break;

			case FILTER_COTIZACION_ID:
				dispatch(cotizacionFilter(value))
				break;

			case FILTER_PEDIDO_ID:
				dispatch(pedidoFilter(value))
				break;

			case FILTER_NOTA_VENTA:
				dispatch(notaVentaFilter(value))
				break;

			default:
				break;
		}
	}


	return (
		<div className={`card mb-50 shadow-none bg-transparent ${isAsignacionMasiva ? 'col-md-6 col-12' : 'col-12'}`}>
			<iframe title='tracking' src={iframeData} style={{ display: 'none' }}></iframe>

			<ListadoHeader
				classes='border rounded-2'
				title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
				handleDisable={isConsulting}
				handleClickSearch={() => handleFetch()}
				handleClickClearFilter={() => handleFetch(true)}
				exportBtn={
					<DownloadFile
						url={selectDepartamentoExportar(departamento)}
						data={{ data: dataFormatter(filtros) }}
					/>
				}
			>
				<>
					<FormInput
						labelText='Búsqueda producto'
						placeholder='Filtrar por SKU o nombre de producto'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA_PRODUCTO)}
						value={busqueda_producto}
					/>

					<FormInput
						labelText='Búsqueda cliente'
						placeholder='Filtrar por RUT, nombre, teléfono, email de cliente'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_BUSQUEDA)}
						value={busqueda_cliente}
					/>

					<FormInput
						labelText='Orden cliente'
						placeholder='Filtrar por # orden'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_ORDEN_CLIENTE)}
						value={orden_cliente}
					/>

					<FormInput
						labelText='Cotización'
						placeholder='Filtrar por # cotización'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_COTIZACION_ID)}
						value={cotizacion_id}
					/>

					<FormInput
						labelText='Pedido'
						placeholder='Filtrar por # pedido'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_PEDIDO_ID)}
						value={pedido_id}
					/>

					<FormInput
						labelText='Nota venta'
						placeholder='Filtrar por # NV'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_NOTA_VENTA)}
						value={nota_venta}
					/>

					<SelectFilter
						labelText='Empresas'
						name='empresas'
						handleValue={(e) => handleAddFilter(e, FILTER_EMPRESA)}
						optionObj={selectFilter.empresas.map(({ id, nombre }) => ({ id, name: nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={empresa?.split('-')?.[1] ?? 'Selecciona'}
					/>

					<SelectFilter
						labelText='Tipo cliente'
						name='role_id'
						handleValue={(e) => handleAddFilter(e, FILTER_TIPO_CLIENTE)}
						optionObj={selectFilter.tiposCliente.map(({ id, name, nombre }) => ({ id, name: name ?? nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={role_id?.split('-')?.[1] ?? 'Selecciona'}
					/>

					<SelectFilter
						labelText='Estado pago'
						name='estado_pago'
						handleValue={(e) => handleAddFilter(e, FILTER_ESTADO_PAGO)}
						optionObj={selectFilter.estadoPago.map(({ id, nombre }) => ({ id, name: nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={estado_pago.split('-')[1] ?? 'Selecciona'}
					/>

					<SelectFilter
						labelText='Estatus pedido'
						name='estatus_pedido'
						handleValue={(e) => handleAddFilter(e, FILTER_ADD_ESTATUS_PEDIDO)}
						optionObj={selectFilter.estatusPedido.map(({ id, nombre }) => ({ id, name: nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={lastPosition(estatus_pedido)}
					/>

					<SelectFilter
						labelText='Método de entrega'
						name='metodos_entrega_id'
						handleValue={(e) => handleAddFilter(e, FILTER_ADD_METODO_ENTREGA)}
						optionObj={selectFilter.metodosEntrega.map(({ id, nombre }) => ({ id, name: nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={lastPosition(metodos_entrega_id)}
					/>

					<SelectFilter
						labelText='Tipo atención'
						name='tipo_atencion'
						handleValue={(e) => handleAddFilter(e, FILTER_ADD_TIPO_ATENCION)}
						optionObj={selectFilter.tiposAtencion.map(({ id, nombre }) => ({ id, name: nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={lastPosition(tipo_atencion)}
					/>

					<SelectFilter
						labelText='Departamento'
						altSeparator
						name='departamento'
						handleValue={(e) => handleAddFilter(e, FILTER_DEPARTAMENTO)}
						optionObj={selectFilter.departamentos.map(({ id, nombre }) => ({ id, name: nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={departamento.split('%-%')[1] ?? 'Selecciona'}
					/>

					<FormInput
						type='date'
						labelText='Desde'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_FECHA_INICIO)}
						value={fecha_inicial}
					/>

					<FormInput
						type='date'
						labelText='Hasta'
						size='col-12'
						sizeDesk='col-md-2'
						handleValue={(e) => handleAddFilter(e, FILTER_FECHA_FINAL)}
						value={fecha_final}
					/>

					<SelectFilter
						labelText='Vendedores'
						name='vendedores'
						handleValue={(e) => handleAddFilter(e, FILTER_ADD_VENDEDOR)}
						optionObj={selectFilter.vendedores.map(({ id, nombre }) => ({ id, name: nombre }))}
						sizeDesk='col-md-2'
						size='col-12'
						value={lastPosition(vendedores)}
					/>
				</>

				{/* FILTROS APLICADOS - ADICIONALES */}
				<p className='mb-25 text-black'>Filtros aplicados</p>
				<div className='row'>
					<FiltroAplicado array={vendedores} func={vendedorFilter} title={'Vendedores'} />
					<FiltroAplicado array={estatus_pedido} func={estatusPedidoFilter} title={'Estatus pedido'} />
					<FiltroAplicado array={tipo_atencion} func={tipoAtencionFilter} title={'Tipo atención'} />
					<FiltroAplicado array={metodos_entrega_id} func={metodoEntregaFilter} title={'Método de entrega'} />
				</div>

				<hr className='my-1' />
			</ListadoHeader>

		</div>
	)
}

export default ReporteVentasFiltros