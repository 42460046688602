import {
   FILTER_ADD_ESTATUS_PEDIDO,
   FILTER_ADD_METODO_ENTREGA,
   FILTER_ADD_TIPO_ATENCION,
   FILTER_ADD_VENDEDOR,
   FILTER_BUSQUEDA,
   FILTER_BUSQUEDA_PRODUCTO,
   FILTER_CLEAR,
   FILTER_COTIZACION_ID,
   FILTER_DEPARTAMENTO,
   FILTER_EMPRESA,
   FILTER_ESTADO_PAGO,
   FILTER_FECHA_FINAL,
   FILTER_FECHA_INICIO,
   FILTER_NOTA_VENTA,
   FILTER_ORDEN_CLIENTE,
   FILTER_PEDIDO_ID,
   FILTER_PERIODO,
   FILTER_REMOVE_ESTATUS_PEDIDO,
   FILTER_REMOVE_METODO_ENTREGA,
   FILTER_REMOVE_TIPO_ATENCION,
   FILTER_REMOVE_VENDEDOR,
   FILTER_TIPO_CLIENTE,
   RESULTADOS_REPORTE_VENTAS,
} from "../redux-types";

const initialState = {
   filtros: {
      cotizacion_id: '', //int
      pedido_id: '', //int
      nota_venta: '', //int
      busqueda_producto: '', //str
      busqueda_cliente: '', //str
      orden_cliente: '', //str
      departamento: '',
      empresa: '',
      estado_pago: '',
      fecha_inicial: '',
      role_id: '',
      fecha_final: '',
      vendedores: [],
      tipo_atencion: [],
      metodos_entrega_id: [],
      estatus_pedido: []
   },
   resultados: null
}


export const reporteVentasReducer = (state = initialState, action) => {
   let isListed

   switch (action.type) {
      case FILTER_ADD_VENDEDOR:
         isListed = state.filtros.vendedores.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               vendedores: isListed
                  ? state.filtros.vendedores
                  : [...state.filtros.vendedores, action.payload]
            }
         };

      case FILTER_REMOVE_VENDEDOR:
         return {
            ...state, filtros: {
               ...state.filtros,
               vendedores: state.filtros.vendedores.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_ESTATUS_PEDIDO:
         isListed = state.filtros.estatus_pedido.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_pedido: isListed
                  ? state.filtros.estatus_pedido
                  : [...state.filtros.estatus_pedido, action.payload]
            }
         };

      case FILTER_REMOVE_ESTATUS_PEDIDO:
         return {
            ...state, filtros: {
               ...state.filtros,
               estatus_pedido: state.filtros.estatus_pedido.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_TIPO_ATENCION:
         isListed = state.filtros.tipo_atencion.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               tipo_atencion: isListed
                  ? state.filtros.tipo_atencion
                  : [...state.filtros.tipo_atencion, action.payload]
            }
         };

      case FILTER_REMOVE_TIPO_ATENCION:
         return {
            ...state, filtros: {
               ...state.filtros,
               tipo_atencion: state.filtros.tipo_atencion.filter(el => el !== action.payload)
            }
         };

      case FILTER_ADD_METODO_ENTREGA:
         isListed = state.filtros.metodos_entrega_id.find(el => el === action.payload)
         return {
            ...state, filtros: {
               ...state.filtros,
               metodos_entrega_id: isListed
                  ? state.filtros.metodos_entrega_id
                  : [...state.filtros.metodos_entrega_id, action.payload]
            }
         };

      case FILTER_REMOVE_METODO_ENTREGA:
         return {
            ...state, filtros: {
               ...state.filtros,
               metodos_entrega_id: state.filtros.metodos_entrega_id.filter(el => el !== action.payload)
            }
         };

      case FILTER_DEPARTAMENTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               departamento: action.payload
            }
         };

      case FILTER_FECHA_INICIO:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_inicial: action.payload
            }
         };

      case FILTER_FECHA_FINAL:
         return {
            ...state, filtros: {
               ...state.filtros,
               fecha_final: action.payload
            }
         };

      case FILTER_PERIODO:
         return {
            ...state, filtros: {
               ...state.filtros,
               periodo: action.payload
            }
         };

      case FILTER_EMPRESA:
         return {
            ...state, filtros: {
               ...state.filtros,
               empresa: action.payload
            }
         };

      case FILTER_TIPO_CLIENTE:
         return {
            ...state, filtros: {
               ...state.filtros,
               role_id: action.payload
            }
         };

      case FILTER_ORDEN_CLIENTE:
         return {
            ...state, filtros: {
               ...state.filtros,
               orden_cliente: action.payload
            }
         };

      case FILTER_COTIZACION_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               cotizacion_id: action.payload
            }
         };

      case FILTER_PEDIDO_ID:
         return {
            ...state, filtros: {
               ...state.filtros,
               pedido_id: action.payload
            }
         };

      case FILTER_NOTA_VENTA:
         return {
            ...state, filtros: {
               ...state.filtros,
               nota_venta: action.payload
            }
         };

      case FILTER_BUSQUEDA_PRODUCTO:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda_producto: action.payload
            }
         };

      case FILTER_BUSQUEDA:
         return {
            ...state, filtros: {
               ...state.filtros,
               busqueda_cliente: action.payload
            }
         };

      case FILTER_ESTADO_PAGO:
         return {
            ...state, filtros: {
               ...state.filtros,
               estado_pago: action.payload
            }
         };

      case RESULTADOS_REPORTE_VENTAS:
         return {
            ...state, resultados: action.payload
         };

      case FILTER_CLEAR:
         return initialState;

      default:
         return state;
   }
};