import {
  FILTER_EMAIL_CLIENTE,
  FILTER_NOMBRE_CLIENTE,
  FILTER_APELLIDO_CLIENTE,
  FILTER_TELEFONO_CLIENTE,
  FILTER_ADD_PUNTO_ENTREGA,
  FILTER_REMOVE_PUNTO_ENTREGA,
  FILTER_ADD_METODO_PAGO,
  FILTER_REMOVE_METODO_PAGO,
  FILTER_CLEAR,
  FILTER_OBSERVACIONES,
  FILTER_RUT_EMPRESA,
  FILTER_REMOVE_TIPO_ATENCION,
  FILTER_ADD_TIPO_ATENCION,
  FILTER_REMOVE_FORMA_PAGO,
  FILTER_ADD_FORMA_PAGO,
  FILTER_REMOVE_FACTURACION,
  FILTER_ADD_FACTURACION,
  FILTER_DIAS_VIGENCIA,
} from "../redux-types";

/* GENERAR COTIZACION - CLIENTE  */
export const emailClienteFilter = (data) => ({ type: FILTER_EMAIL_CLIENTE, payload: data });
export const nombreClienteFilter = (data) => ({ type: FILTER_NOMBRE_CLIENTE, payload: data });
export const apellidoClienteFilter = (data) => ({ type: FILTER_APELLIDO_CLIENTE, payload: data });
export const telefonoClienteFilter = (data) => ({ type: FILTER_TELEFONO_CLIENTE, payload: data });
export const rutEmpresaFilter = (data) => ({ type: FILTER_RUT_EMPRESA, payload: data });

/* GENERAR COTIZACION - COTIZACION */
export const puntoEntregaFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_PUNTO_ENTREGA, payload: data } : { type: FILTER_ADD_PUNTO_ENTREGA, payload: data };

export const tipoAtencionFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_TIPO_ATENCION, payload: data } : { type: FILTER_ADD_TIPO_ATENCION, payload: data };

export const metodoPagoFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_METODO_PAGO, payload: data } : { type: FILTER_ADD_METODO_PAGO, payload: data };

export const formaPagoFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_FORMA_PAGO, payload: data } : { type: FILTER_ADD_FORMA_PAGO, payload: data };

export const facturacionFilter = (data, isRemove = false) =>
  isRemove ? { type: FILTER_REMOVE_FACTURACION, payload: data } : { type: FILTER_ADD_FACTURACION, payload: data };

export const diasVigenciaFilter = (data) => ({ type: FILTER_DIAS_VIGENCIA, payload: data });
export const observacionesFilter = (data) => ({ type: FILTER_OBSERVACIONES, payload: data });

export const clearFilterGenerarCotizacion = () => ({ type: FILTER_CLEAR });
