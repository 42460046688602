import React, { useEffect, useState } from 'react'


// Redux
import { useDispatch, useSelector } from 'react-redux'
import { fireToaster } from '../../../redux/actions/toaster'
import { FILTER_AUTOGESTION_INPUT } from '../../../redux/redux-types'
import { autogestionInputFilter, clearFilterAutogestion, resultadosAutogestion } from '../../../redux/actions/autogestion'


// Components
import FormInput from '../../FormInput'
import SelectFilter from '../../SelectFilter'
import DownloadFile from '../../Download/DownloadFile'


// Layouts
import ListadoHeader from '../../../layout/ListadoHeader'


// Helpers
import { getAll } from '../../../endpoints/getAll'
import { filtrosAplicadosTotal } from '../../../helpers/filtrosAplicadosTotal'
import { responseToasterContent } from '../../../helpers/toasterContent'
import { queryParamsFormatter } from '../../../helpers/queryParamsFormatter'


// Services
import { filtrosFormatter, getAllAutogestion } from '../../../services/autogestion/getAllAutogestion.service'
import { getAllComunas } from '../../../services/comunas/getAllComunas.service'
import { getAllGraficas } from '../../../services/autogestion/clientes/getGraphics.service'
import { getAllSucursales } from '../../../services/autogestion/sucursales/getAll.service'


const CONSTANT = {
  redirectUrl: '/autogestion',
  reduxStateSelector: 'autogestion',
  title: 'Filtros de autoservicio',

  queryExportar: `${getAll.autogestion}/exportar`,

  reduxClearFilters: clearFilterAutogestion,
  reduxSetResults: resultadosAutogestion,
  nullValue: { id: 'NULL', nombre: 'Sin selección' },

  selectFilterInitialState: {
    comunas: [], sucursales: [],
  },
}

export const handleSelectsData = async () => {
  const comunas = await getAllComunas(999999)
    .then(({ comunas, ...rest }) => {
      if (rest.status >= 400) return []
      return [...comunas.data]
    })

  const sucursales = await getAllSucursales(undefined, 999999)
    .then(({ data }) => {
      return [...data.data]
    })
    .catch(err => {
      console.error(err)
      return []
    })

  return { comunas, sucursales }
}


const fetchData = async (limit, filters) => {
  const path = window.location.pathname
  const serviceDict = {
    '/autoservicio': getAllAutogestion,
    '/autoservicio/graficas': getAllGraficas
  }

  return await serviceDict[path](limit, filters)
    .then((res) => res)
    .catch(err => err)
}


export const AutoservicioFiltros = () => {
  const { filtros } = useSelector(state => state[CONSTANT.reduxStateSelector])
  const { showing_results } = useSelector(state => state.listedResults)
  const { comuna_id, pedido_id, fecha_desde, fecha_hasta, sucursal_id, nota_venta } = filtros
  const dispatch = useDispatch()
  const [selectFilter, setSelectFilter] = useState(CONSTANT.selectFilterInitialState)
  const [isConsulting, setIsConsulting] = useState(false)


  // EFECTO QUE RENDERIZA POR PRIMERA VEZ LA LISTA
  useEffect(() => {
    setIsConsulting(true)
    fetchData(showing_results)
      .then(({ data }) => dispatch(CONSTANT.reduxSetResults(data)))
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
      })

    handleSelectsData().then(res => setSelectFilter(res))

    setIsConsulting(false)

    return () => dispatch(CONSTANT.reduxClearFilters())
  }, [showing_results, dispatch])


  // FUNCION QUE CONSULTA EL BACKEND CON LOS FILTROS SELECCIONADOS
  const handleFetchFilter = async (reset = false) => {
    if (reset) {
      dispatch(CONSTANT.reduxClearFilters())
      // getAllAutogestion(showing_results).then(res => dispatch(CONSTANT.reduxSetResults(res)))
      return
    }

    setIsConsulting(true)

    await fetchData(showing_results, filtros)
      .then(({ data }) => {
        dispatch(CONSTANT.reduxSetResults(data))
      })
      .catch(err => {
        dispatch(fireToaster(responseToasterContent(err)))
        dispatch(CONSTANT.reduxSetResults([]))
      })
      .finally(() => {
        setIsConsulting(false)
      })
  }

  // MANEJADORES DE FILTRO
  const handleAddFilter = (e, filter) => {
    const { value, name: key } = e.target

    switch (filter) {
      case FILTER_AUTOGESTION_INPUT:
        dispatch(autogestionInputFilter({ key, value }))
        break;

      default:
        break;
    }
  }


  return (
    <div className="card mb-50 shadow-none bg-transparent">


      <ListadoHeader
        classes='border rounded-2'
        title={`${CONSTANT.title} (${filtrosAplicadosTotal(filtros)})`}
        handleDisable={isConsulting}
        handleClickSearch={() => handleFetchFilter()}
        handleClickClearFilter={() => handleFetchFilter(true)}
        exportBtn={
          window.location.pathname === '/autoservicio'
            ? <DownloadFile
              method='GET'
              url={CONSTANT.queryExportar + '?s=1' + queryParamsFormatter(filtrosFormatter(filtros))}
            />

            : <></>
        }
      >
        <>
          <FormInput
            labelText='Fecha desde'
            name='fecha_desde'
            size='col-12'
            sizeDesk='col-md-2'
            type='date'
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={fecha_desde}
            max={fecha_hasta}
          />

          <FormInput
            min={fecha_desde}
            labelText='Fecha hasta'
            name='fecha_hasta'
            size='col-12'
            sizeDesk='col-md-2'
            type='date'
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={fecha_hasta}
          />

          <SelectFilter
            labelText="Sucursal"
            name='sucursal_id'
            altSeparator
            size="col-12"
            sizeDesk={"col-md-3"}
            optionObj={selectFilter?.sucursales?.concat([CONSTANT.nullValue]).map(({ id, nombre }) => ({ id, name: nombre }))}
            handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
            value={sucursal_id.split('%-%')[1] ?? 'Seleccione'}
          />

          {window.location.pathname === '/autoservicio' &&
            <>
              {/* <FormInput
                labelText='# Pedido'
                placeholder='Ingrese un número de pedido'
                name='pedido_id'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
                value={pedido_id}
              /> */}

              <FormInput
                labelText='# Nota de venta'
                placeholder='Ingrese un número de NV'
                name='nota_venta'
                size='col-12'
                sizeDesk='col-md-2'
                handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
                value={nota_venta}
              />

              <SelectFilter
                labelText="Comuna"
                name='comuna_id'
                altSeparator
                size="col-12"
                sizeDesk={"col-md-3"}
                optionObj={selectFilter?.comunas?.concat([CONSTANT.nullValue]).map(({ id, nombre }) => ({ id, name: nombre }))}
                handleValue={(e) => handleAddFilter(e, FILTER_AUTOGESTION_INPUT)}
                value={comuna_id.split('%-%')[1] ?? 'Seleccione'}
              />
            </>
          }
        </>
      </ListadoHeader>
    </div>
  )
}